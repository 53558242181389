import classnames from "classnames";
import React, { ReactNode } from "react";
import { useHover } from "react-laag";
import { TipoDeFormatacao } from "./TipoDeFormatacao";

const LabelsTipoDeFormatacao = {
  [TipoDeFormatacao.Italico]: 'Itálico',
  [TipoDeFormatacao.Negrito]: "Negrito",
  [TipoDeFormatacao.Sublinhado]: "Sublinhado",
  [TipoDeFormatacao.Lista]: "Lista"
};

const TooltipDeFormatacao: React.FC<{ visivel: boolean; }> = ({ children, visivel }) => (
  <div className={classnames(
    "absolute -top-11 bg-white rounded-md -left-2/4 border border-solid p-2 font-bold",
    { "visible": visivel, "invisible": !visivel }
  )}>
    {children}
  </div>
);

const ContainerDoFormatador: React.FC<
  {
    valor: TipoDeFormatacao;
  }
> = ({ children, valor, ...props }) => (
  <div
    className="relative"
    {...props}
  >
    {children}
  </div>
);

interface PropriedadesDoBotaoDeFormatacao {
  botao: ReactNode;
  valor: TipoDeFormatacao;
}

export const BotaoDeFormatacao = ({ botao, valor }: PropriedadesDoBotaoDeFormatacao) => {
  const [estaComHover, propriedadesDoHover] = useHover();
  return <ContainerDoFormatador {...{ ...propriedadesDoHover, valor }}>
    {botao}
    <TooltipDeFormatacao
      visivel={estaComHover}
    >
      {LabelsTipoDeFormatacao[valor]}
    </TooltipDeFormatacao>
  </ContainerDoFormatador>;
};
