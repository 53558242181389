import { observer } from "mobx-react-lite";
import { IndicadorDeCarregamento } from "@xapps/design-system";

import { useMobxStores } from "../../hooks/useMobxStores";

export const ProgressoDoUpload = observer(() => {
  const { messages } = useMobxStores();
  const { progressoGeralDoUpload } = messages;

  if (progressoGeralDoUpload === null) return null;

  return (
    <div className="flex items-center mr-2">
      <span className="w-4 h-4 mr-2">
        <IndicadorDeCarregamento className="stroke-current text-purple-600" />
      </span>
      Enviando arquivos ({progressoGeralDoUpload}%)
    </div>
  );
});
