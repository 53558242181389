import { makeAutoObservable, reaction, toJS } from 'mobx';
import axios from 'axios';
import ptBR from 'date-fns/locale/pt-BR';
import queryString from 'query-string';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mean from 'lodash/mean';
import merge from 'lodash/merge';
import round from 'lodash/round';

import { configuracoes } from '../../package.json';
import { TipoDaMensagem } from '../componentes/chat/mensagem';
import { api } from '../services/api';
import { compressorDeImagem } from '../utilitarios/compressorDeImagem';
import { ehMensagemIgnorada } from '../utilitarios/ehMensagemIgnorada';
import { authenticator, Authenticator } from './authenticator';
import { Notificator, notificator } from './notificator';
import { Users, users } from './users';
import { getSocket } from '../services/socket';

interface Membro {
  eh_bot: boolean;
  eh_usuario_app: boolean;
  nome: string;
  deletado: boolean;
}

const MILISSEGUNDOS_PRA_ATUALIZAR_NAO_LIDAS = 10 * 1000;

function agrupar(mensagens: any[]) {
  const mensagensAgrupadas = groupBy(mensagens.slice(), (mensagem: any) =>
    formatDistanceToNow(new Date(mensagem.ts * 1000), {
      locale: ptBR,
      addSuffix: true,
    }),
  );

  return Object.entries(mensagensAgrupadas).flatMap(([key, values]) => [
    ...values,
    {
      tipo: 'agrupador',
      titulo: key,
    },
  ]);
}

class Messages {
  proximo: string | undefined;
  paginas: any = {};
  threadAberta: TipoDaMensagem | null = null;
  threadMensagens: any = {};
  temMais: boolean = true;
  cursores: Set<string> = new Set();
  estaCarregandoPagina: boolean = false;
  estaCarregandoThreads: boolean = false;
  canal: any = undefined;
  totalDoUpload: number | null = null;
  progressos: number[] = [];
  membrosDoCanal: { [key: string]: any } = {};
  mensagensNaoLidas: { [key: string]: number } = {};
  interval: NodeJS.Timeout;

  constructor(
    private readonly notificador: Notificator,
    private readonly usuarios: Users,
    private readonly autenticacao: Authenticator,
  ) {
    makeAutoObservable(this);
    const socket = getSocket();

    reaction(
      () => [this.canal],
      (canal, canalAntigo) => {
        this.paginas = {};
        this.cursores = new Set();
        this.temMais = true;
        this.proximo = undefined;

        socket.off(`message:${canalAntigo}`);

        socket.on(`message:${canal}`, (evento: any) => {
          if (
            !ehMensagemIgnorada(evento) &&
            !this.jaExisteAMensagem(evento.text)
          )
            this.paginas['ultima'].unshift(evento);
          this.gravarUltimaVisualizacao();
        });
      },
    );

    this.interval = setInterval(
      () => this.obterMensagensNaoLidas(),
      MILISSEGUNDOS_PRA_ATUALIZAR_NAO_LIDAS,
    );

    reaction(
      () => [this.usuarios.users.length],
      async () => await this.definirMembrosDoCanal(),
    );
  }

  obterProximasMensagens = async () => {
    return this.obterMensagens(this.proximo);
  };

  definirCanal = async (canal: any) => {
    this.canal = canal;

    await this.definirMembrosDoCanal();
  };

  definirMembrosDoCanal = async () => {
    if (Object.keys(this.usuarios.users).length) {
      this.membrosDoCanal = keyBy(
        (await this.obterMembros())
          .map((membro: string) => this.usuarios.users[membro])
          .filter((membro: Membro) => {
            return (
              !membro.eh_bot &&
              !membro.eh_usuario_app &&
              !membro.deletado &&
              membro.nome !== configuracoes.nomePadraoDoColaborador
            );
          }),
        'id',
      );

      this.membrosDoCanal[configuracoes.nomePadraoDoColaborador] = {
        nome: configuracoes.nomePadraoDoColaborador,
        id: configuracoes.nomePadraoDoColaborador,
      };
    }
  };

  obterMembros = async () => {
    if (!this.canal) return [];

    const response = await api.get(`/canais/${this.canal}/membros`);

    if (response.status === 200) {
      const membros = response.data;
      return membros.membros;
    }
  };

  jaExisteAMensagem = (mensagem: string) => {
    return !!this.paginas['ultima'].find(
      (mensagemAdicionada: any) =>
        mensagemAdicionada.text === mensagem &&
        mensagemAdicionada.username === this.autenticacao.user?.name,
    );
  };

  obterMensagensNaoLidas = async () => {
    if (!this.canal) return;

    const response = await api.get(`/canais/nao-lidas`);

    if (response.status === 200) {
      this.mensagensNaoLidas = response.data.mensagensNaoLidas;
    }
  };

  incrementarMensagemNaoLidaDoCanal = (canal: {
    id: number;
    idDoCanalNoSlack: string;
  }) => {
    const ehOCanalAberto = canal.idDoCanalNoSlack === this.canal;
    if (ehOCanalAberto) return;

    this.mensagensNaoLidas[canal.id] =
      (this.mensagensNaoLidas[canal.id] || 0) + 1;
  };

  obterMensagens = async (cursor?: string) => {
    try {
      if (!this.temMais || !this.canal) return;

      this.estaCarregandoPagina = true;

      const resposta = await api.get(
        `/canais/${this.canal}/mensagens?${queryString.stringify({ cursor })}`,
      );

      if (resposta.status === 200) {
        const mensagens = resposta.data;
        this.proximo = mensagens.proximo;

        const cursorCarregado = cursor ?? 'ultima';
        
        this.cursores.add(cursorCarregado);
        this.paginas[cursorCarregado] = agrupar(mensagens.mensagens);
        this.temMais = mensagens.temMais;

        if (cursorCarregado === 'ultima') this.gravarUltimaVisualizacao();

        // console.log("paginas", toJS(this.paginas[cursorCarregado]));

        return this.paginas[cursorCarregado];
      }
    } finally {
      this.estaCarregandoPagina = false;
    }
  };

  definirThreadAberta = (mensagem: TipoDaMensagem | null) => {
    this.threadAberta = mensagem;
  };

  obterMensagensThread = async (ts: number) => {
    try {
      if (!this.canal) return;
      this.estaCarregandoThreads = true;
      const response = await api.get(`/canais/${this.canal}/mensagens/${ts}`);

      if (response.status === 200) {
        const mensagens = response.data;
        this.threadMensagens[ts] = agrupar(mensagens.mensagens);

        return this.threadMensagens[ts];
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.estaCarregandoThreads = false;
    }
  };

  limparMensagensNaoLidas = (canal: { id: number }) => {
    this.mensagensNaoLidas[canal.id] = 0;
  };

  gravarUltimaVisualizacao = async () => {
    if (!this.canal) return;

    await api.post(`/canais/${this.canal}/ultima-visualizacao`, {
      data: this.dataDaUltimaMensagemLida,
    });
  };

  get mensagens() {
    const data = [...this.cursores]
      .flatMap((cursor) => this.paginas[cursor] ?? [])
      // .filter((msg) =>
      //   !!msg.thread_ts ? (msg.thread_ts === msg.ts ? msg : null) : msg,
      // )
      .reverse();

    // data.map((data, index) => {
    //   console.log(`Mensagem: ${index}`, toJS(data));
    // });
    // console.log('Mensagens: ', data);

    return data;
  }

  get mensagensDaThread() {
    const threadId = this.threadAberta?.thread_ts || this.threadAberta?.ts;
    return [threadId]
      .flatMap((thread) => this.threadMensagens[thread!] ?? [])
      .filter((msg) => msg.ts !== this.threadAberta!.ts);
  }

  get paginasCarregadas() {
    return Object.keys(this.paginas).length;
  }

  get progressoGeralDoUpload() {
    if (!this.progressos.length) return null;
    return round(mean(this.progressos));
  }

  get dataDaUltimaMensagemLida() {
    const ts = get(this.paginas, 'ultima.0.ts');
    return ts ? new Date(ts * 1000) : new Date();
  }

  enviarMensagem = async (canal: string, mensagem: string, arquivos = []) => {
    try {
      let threadId = toJS(this.threadAberta)?.thread_ts || toJS(this.threadAberta)?.ts;

      this.adicionarMensagem(
        mensagem,
        () => this.enviarMensagem(canal, mensagem, arquivos),
        threadId,
      );

      this.progressos = arquivos.map(() => 0);

      if (mensagem) {
        const response = await api.post(`/canais/${canal}/enviar`, {
          canal,
          mensagem,
          urlDoAvatar: this.autenticacao.user?.avatar_url,
          thread_ts: threadId,
        });

        threadId = response.data.ts;

        if (response.status !== 200) {
          throw new Error('Erro na resposta do envio da mensagem');
        }
      }

      const thread = !!this.threadAberta
        ? this.threadAberta.thread_ts || this.threadAberta.ts
        : null;

      await Promise.all(
        arquivos.map(async (arquivo: File, index) => {
          const corpo = new FormData();

          const url = thread
            ? `/canais/${canal}/upload?thread=${thread}`
            : `/canais/${canal}/upload`;

          if (!!arquivo.type.match('image')) {
            const imgComprimida = await compressorDeImagem(arquivo, {
              quality: 0.6,
            });
            corpo.append('arquivo', imgComprimida, arquivo.name);
          } else {
            corpo.append('arquivo', arquivo, arquivo.name);
          }

          return axios
            .request({
              method: 'POST',
              url: `${process.env.REACT_APP_URL_DA_API}${url}`,
              ...merge(
                {
                  headers: {
                    authorization: `Bearer ${localStorage.getItem(
                      'xapps:chat@token',
                    )}`,
                  },
                },
                {
                  data: corpo,
                  onUploadProgress: (progress: any) => {
                    this.progressos[index] = Math.floor(
                      (progress.loaded * 100) / progress.total,
                    );
                  },
                },
              ),
            })
            .then((response) => {
              if (response.status === 401) authenticator.expireSession();
              return response;
            });
        }),
      );
      this.finalizarEntregaDaMensagem(mensagem, String(threadId));
    } catch {
      this.falharMensagem(mensagem);
      this.notificador.erro('Não foi possível enviar sua mensagem');
    } finally {
      this.progressos = [];
    }
  };

  adicionarMensagem = (
    mensagem: string,
    retry: () => {},
    isThread?: number,
  ) => {
    const mensagemEnviada = this.paginas['ultima'].find(
      (mensagemExibida: any) =>
        mensagemExibida.text === mensagem && mensagemExibida.failed,
    );

    if (mensagemEnviada) return;

    this.paginas['ultima'].unshift({
      text: mensagem,
      ts: (+new Date() / 1000).toString(),
      type: 'message',
      icons: { image_48: this.autenticacao.user?.avatar_url },
      username: this.autenticacao.user?.name,
      sending: true,
      failed: false,
      retry,
      subtype: isThread ? 'thread_broadcast' : 'bot_message',
    });
  };

  falharMensagem = (mensagem: string) => {
    const indiceDaMensagemDaLista = this.paginas['ultima'].findIndex(
      (mensagemAdicionada: any) =>
        mensagemAdicionada.text === mensagem &&
        mensagemAdicionada.sending &&
        mensagemAdicionada.username === this.autenticacao.user?.name,
    );

    if (indiceDaMensagemDaLista < 0) return;

    this.paginas['ultima'][indiceDaMensagemDaLista] = {
      ...this.paginas['ultima'][indiceDaMensagemDaLista],
      failed: true,
      sending: false,
    };
  };

  finalizarEntregaDaMensagem = (mensagem: string, threadId: string) => {
    const indiceDaMensagemDaLista = this.paginas['ultima'].findIndex(
      (mensagemAdicionada: any) =>
        mensagemAdicionada.text === mensagem &&
        mensagemAdicionada.sending &&
        mensagemAdicionada.username === this.autenticacao.user?.name,
    );

    if (indiceDaMensagemDaLista < 0) return;

    this.paginas['ultima'][indiceDaMensagemDaLista] = {
      ...this.paginas['ultima'][indiceDaMensagemDaLista],
      ts: threadId,
      failed: false,
      sending: false,
    };
  };
}

const INSTANCE = new Messages(notificator, users, authenticator);

export { Messages, INSTANCE as messages };
