import React from 'react';
import { Rnd } from 'react-rnd';

import classnames from 'classnames';

import { useMediaQueries } from '../hooks/useMediaQueries';
import { useSideBarWidth } from '../hooks/useSideBarWidth';

interface ISideBarProps {
  isOpenMenu: boolean;
}

const SideBar: React.FC<ISideBarProps> = ({ isOpenMenu, children }) => {
  const { isMobile, isTablet } = useMediaQueries();
  const { sideBarWidth, updateWidth } = useSideBarWidth();

  const classNames = classnames(
    (isMobile || isTablet) && {
      'translate-x-0': isOpenMenu,
      '-translate-x-full': !isOpenMenu,
    },
    `md:-ml-5 h-screen
    md:relative md:translate-x-0 md:col-span-2
    md:w-auto md:min-w-max md:max-w-2xl transform top-0 left-0
    fixed overflow-x-auto overflow-y-scroll overscroll-contain ease-in-out
    transition-all md:transition-none duration-300 z-30 w-screen`,
    'h-full max-h-screen',
    'bg-white md:bg-blue-primary md:resize-x',
  );

  if (isMobile || isTablet) {
    return <aside className={classNames}>{children}</aside>;
  }

  return (
    <Rnd
      style={{ position: 'relative', overflowY: 'auto', overflowX: 'hidden' }}
      enableResizing={{ right: true }}
      disableDragging
      className={classNames}
      minWidth={200}
      default={{ x: 0, y: 0, height: 'unset', width: sideBarWidth ?? 'auto' }}
      onResizeStop={(_e, _dir, ref) => updateWidth(ref.offsetWidth.toString())}
      maxWidth={500}
    >
      {children}
    </Rnd>
  );
}

export { SideBar };
