import { memo } from "react";
import { TipoDaMensagem } from "./mensagem";

interface PropriedadesDosAnexosDaMensagem {
  mensagem: TipoDaMensagem;
  processarMensagem(texto: string): string;
}

export const AnexosDaMensagem = memo(
  ({ mensagem, processarMensagem }: PropriedadesDosAnexosDaMensagem) => {
    return (
      <>
        {mensagem.attachments?.length && (
          <div className="py-2 pl-2 border-l-4 border-gray-400 ">
            {mensagem.attachments?.map((anexo, indiceDoAnexo) => (
              <div
                key={indiceDoAnexo}
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html: anexo.text ? processarMensagem(anexo.text) : "",
                }}
              ></div>
            ))}
          </div>
        )}
      </>
    );
  }
);
