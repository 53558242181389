import get from "lodash/get";
import queryString from "query-string";
import { Paperclip } from "react-feather";
import { DownloadDaApi } from "./DownloadDaApi";

interface Arquivo {
  title: string;
  url_private: string;
}

interface PropriedadesDoAnexo {
  arquivo: Arquivo;
}

export const Anexo = ({ arquivo }: PropriedadesDoAnexo) => {
  return (
    <DownloadDaApi
      lazy
      nomeDoArquivo={arquivo.title}
      url={`/download?${queryString.stringify({
        url: get(arquivo, "url_private"),
      })}`}
    >
      {(_, carregando) => (
        <div className="cursor-pointer w-32 shadow h-36 rounded bg-white border hover:shadow-md px-2 py-2 flex flex-col justify-center items-center">
          <Paperclip />
          <span
            title={arquivo.title}
            className="text-xs whitespace-nowrap overflow-ellipsis overflow-hidden text-center w-28 mt-2"
          >
            {carregando ? "Carregando" : arquivo.title}
          </span>
        </div>
      )}
    </DownloadDaApi>
  );
};
