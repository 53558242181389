import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useMobxStores } from '../hooks/useMobxStores';
import DisabledChannel from '../components/DisabledChannel';
import BarraLateral from '../componentes/chat/BarraLateral';
import { LoadingChannels } from '../components/LoadingChannel';
import ListaPrincipal from '../componentes/mensagens/ListaPrincipal';
import { EmptySelectedChannel } from '../components/EmptySelectedChannel';
import { FormularioDeMensagem } from '../componentes/chat/FormularioDeMensagem';

type Props = { 
    canalSelecionado: any,
    nomeDoCanal: string
}

const MessagesContent = (props: Props): JSX.Element => {

    const { channels } = useMobxStores();
    const location = useLocation<{ canal: any }>();
    const { loadingChannels } = channels;

    // *************************************************************************** //
    // CHANGED TO 'suspenso' inside 'canalSelectionado' to update data
    // thought browser updates (using location, the channel state does not
    // updated trought browser relaods because the state is saved on location)
    // *************************************************************************** //
    // const canalEstaSuspenso = location?.state?.canal?.suspenso ?? false;
    const canalEstaSuspenso = props.canalSelecionado?.suspenso ?? false;
    if (!!loadingChannels) return <LoadingChannels />
    if (!props.canalSelecionado) return <EmptySelectedChannel channelName={props.nomeDoCanal} />
    if (!!canalEstaSuspenso) return <DisabledChannel channel={location.state.canal} />;

    return (
        <>
            <ListaPrincipal canalSelecionado={props.canalSelecionado} />
            <BarraLateral canalSelecionado={props.canalSelecionado} />
            <FormularioDeMensagem
                aposEnviarMensagem={console.log}
                canalSelecionado={props.canalSelecionado}
            />
        </>
    )
}

const Component = observer(MessagesContent);

export { Component as MessagesContent };