import { makeAutoObservable, reaction } from 'mobx';

import { Messages, messages } from './messages';
import { getSocket } from '../services/socket';
import { api } from '../services/api';

export interface IChannel {
  idDoCanalNoSlack: string;
  nomeDoCanal: string;
}

class Channels {

  loadingChannels: boolean = false
  channels = [] as any[];

  constructor(private readonly mensagens: Messages) {
    makeAutoObservable(this);

    const socket = getSocket();

    reaction(
      () => [this.channels],
      ([novosCanais]) => {
        socket.off();

        novosCanais.forEach((channel: any) => {
          socket.on(`message:${channel.idDoCanalNoSlack}`, () => {
            this.mensagens.incrementarMensagemNaoLidaDoCanal(channel);
          });
        });
      }
    );
  }

  getChannels = () => {
    this.loadingChannels = true
    api.get('/canais').then(response => {
      if (response.status === 200) {
        this.channels = response.data.canais;
      }
    }).finally(() => {
      this.loadingChannels = false
    })
  };
}

const INSTANCE = new Channels(messages);

export { Channels, INSTANCE as channels };
