import React from "react";
import { Bold, Italic, List, Underline } from "react-feather";
import { BotaoDeFormatacao } from "./BotaoDeFormatacao";
import { TipoDeFormatacao } from "./TipoDeFormatacao";

export const BarraDeFormatacao = ({
  referenciaDoInput,
  alterarValor,
}: {
  referenciaDoInput: any;
  alterarValor(novoValor: string): void;
}) => {
  const formatarInput = (caracter: string) => (e: any) => {
    const deveFecharMarcacao = caracter !== "- ";
    e.preventDefault();

    const comeco = referenciaDoInput.current.value.slice(
      0,
      referenciaDoInput.current?.selectionStart
    );
    const fim = referenciaDoInput.current.value.slice(
      referenciaDoInput.current?.selectionEnd
    );
    const textoSelecionado = window.getSelection()?.toString();

    alterarValor(
      `${comeco}${caracter}${textoSelecionado}${
        deveFecharMarcacao ? caracter : "\n"
      }${fim}`
    );

    const inicioDaSelecao =
      (referenciaDoInput.current?.selectionStart || 0) + 1;

    referenciaDoInput.current.selectionStart = inicioDaSelecao;
    referenciaDoInput.current.selectionEnd = inicioDaSelecao;
  };

  return (
    <span className="hidden cursor-pointer md:inline-flex">
      <BotaoDeFormatacao botao={<Bold
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          onClick={formatarInput("*")}
          className="mr-4"
        />} valor={TipoDeFormatacao.Negrito} />

      <BotaoDeFormatacao botao={<Italic
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          onClick={formatarInput("_")}
          className="mr-4"
        />} valor={TipoDeFormatacao.Italico} />

      <BotaoDeFormatacao botao={<Underline
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          onClick={formatarInput("~")}
          className="mr-4"
        />} valor={TipoDeFormatacao.Sublinhado} />

      <BotaoDeFormatacao botao={<List
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          onClick={formatarInput("- ")}
        />} valor={TipoDeFormatacao.Lista} />
    </span>
  );
};
