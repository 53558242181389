type Props = {
  channel: any;
};

const DisabledChannel = ({ channel }: Props) => {
  const channelName = channel.nomeDoCanal ?? '';

  return (
    <div className="h-screen flex flex-col relative items-center justify-center">
      <h1 className="text-blue-primary text-xl">
        O canal <strong>{channelName}</strong> está suspenso no momento
      </h1>
      <h2 className="text-gray-2 text-md mt-1">
        Para mais informações, entre em contato com nossa equipe
      </h2>
    </div>
  );
};

export default DisabledChannel;
