import Compressor from 'compressorjs';

export const compressorDeImagem = async (
  imagem: File,
  opts: Compressor.Options = {},
): Promise<Blob> => (
  await new Promise((resolve, reject) => {
    new Compressor(imagem, {
      ...opts,
      success: resolve,
      error: reject,
    });
  })
);
