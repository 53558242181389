import { Smartphone } from 'react-feather';
import { observer } from 'mobx-react-lite';
import { ListaDeCanaisItems } from './canais-list';

interface PropriedadesDaListaDeCanais {
  aoEscolherCanal(canal: { id: number }): void;
  mostrarModalQRCode: () => void;
}

export const ListaDeCanais = observer(
  ({ aoEscolherCanal, mostrarModalQRCode }: PropriedadesDaListaDeCanais) => {
    return (
      <nav>
        <div className="md:block grid grid-cols-4 gap-5 md:px-5 px-4">
          <div className="col-span-full py-4 ">
            <div
              className="hidden md:flex mb-4 font-bold md:text-white text-xs items-center cursor-pointer"
              onClick={mostrarModalQRCode}
            >
              <Smartphone style={{ marginRight: '6px' }} /> Acessar pelo celular
            </div>

            <ul className="text-blue-primary">
              <li className="mb-4 font-bold md:text-white text-base">
                Projetos
              </li>
              <ListaDeCanaisItems aoEscolherCanal={aoEscolherCanal} />              
            </ul>
          </div>
        </div>
      </nav>
    );
  },
);