import { version } from "./../../../package.json"
export const VersionControl = (): JSX.Element => {

    const refreshCacheAndReload = async () => {
        if ('caches' in window) {
            // delete browser cache if it exists
            const cachekeys = await window.caches.keys();
            for (const cachekey of cachekeys) {
                caches.delete(cachekey)
            }
        }
        // hard reload
        window.location.replace(window.location.href);
    }

    return (
        <div style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 15,
            paddingRight: 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "rgb(0, 52, 107)"
        }}>
            <label style={{ color: "white", fontSize: 11, marginRight: 15 }}>
                versão::{version}
            </label>
            <button
                onClick={refreshCacheAndReload}
                style={{
                    fontSize: 11,
                    backgroundColor: "transparent",
                    marginLeft: 15,
                    color: "white",
                    textDecoration: "underline"
                }}
            >
                limpar cache
            </button>
        </div>
    )
}