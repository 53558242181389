import { useEffect } from "react";
import classNames from "classnames";
import { Arquivo } from "./ArquivosAnexosDaMensagem";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useMobxStores } from "../../hooks/useMobxStores";

interface PropriedadesDoEmailAnexo {
  arquivo: Arquivo;
  aposAbrirEmail(): void;
}

function EmailAnexo({ arquivo, aposAbrirEmail }: PropriedadesDoEmailAnexo) {
  const { openedEmails } = useMobxStores();
  const { openeds, definirEmailAberto } = openedEmails;

  const estaAberto = !!openeds[arquivo.id];
  const definirSeEstaAberto = () => definirEmailAberto(arquivo.id, true);

  useEffect(() => {
    if(typeof aposAbrirEmail === 'function') aposAbrirEmail();
  }, [estaAberto, aposAbrirEmail]);
  return (
    <div
      className={classNames(
        "border col-span-3 flex flex-wrap justify-center relative bg-gray-100 border-gray p-2 whitespace-pre-line truncate",
        {
          "h-24 cursor-pointer": !estaAberto,
          "h-auto": estaAberto,
        }
      )}
      onClick={!estaAberto ? () => definirSeEstaAberto() : undefined}
    >
      {estaAberto && (
        <div className="w-full">
          <div>
            De:{" "}
            {arquivo.from
              .map((x: { original: string }) => x.original)
              .join(", ")}
          </div>
          <div>
            Para:{" "}
            {arquivo.to.map((x: { original: string }) => x.original).join(", ")}
          </div>
          <div>Título: {arquivo.title}</div>
        </div>
      )}

      {estaAberto ? (
        <iframe
          title={arquivo.title}
          className="w-full h-96"
          srcDoc={arquivo.preview}
        />
      ) : (
        arquivo.preview_plain_text
      )}

      {!estaAberto && (
        <div className="absolute opacity-90  mx-auto p-2 shadow bottom-4 rounded bg-gray-600 text-white">
          Clique para ver mais
        </div>
      )}
    </div>
  );
}

export default React.memo(observer(EmailAnexo));
