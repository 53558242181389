import { useRef, useEffect, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import debounce from 'lodash/debounce';

import { useMobxStores } from '../../hooks/useMobxStores';
import ListaDeMensagens from './ListaDeMensagens';

interface PropriedadesDaListaDeMensagens {
  canalSelecionado: any;
}

const ListaPrincipal = ({
  canalSelecionado,
}: PropriedadesDaListaDeMensagens) => {
  const { messages } = useMobxStores();

  const referenciaDaLista = useRef<any>(null);

  const {
    estaCarregandoPagina,
    paginasCarregadas,
    mensagens: listaDeMensagens,
    definirCanal,
    obterProximasMensagens,
  } = messages;

  useEffect(() => {
    definirCanal(canalSelecionado?.idDoCanalNoSlack);
  }, [canalSelecionado?.idDoCanalNoSlack, definirCanal]);

  const obterProximaPagina = useCallback(
    debounce(async () => {
      await obterProximasMensagens();
    }, 500),
    [canalSelecionado?.idDoCanalNoSlack, obterProximasMensagens],
  );

  // Inicializa a primeira página
  useEffect(() => {
    obterProximaPagina();
  }, [obterProximaPagina]);

  return (
    <ListaDeMensagens
      carregando={estaCarregandoPagina}
      canal={canalSelecionado}
      ref={referenciaDaLista}
      listaDeMensagens={listaDeMensagens}
      obterProximaPagina={obterProximaPagina}
      paginasCarregadas={paginasCarregadas}
    />
  );
};

export default observer(ListaPrincipal);
