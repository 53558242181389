import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../hooks/useMobxStores';

interface PropriedadesDaListaDeCanaisItems {
    aoEscolherCanal(canal: { id: number }): void;
}

function estaNaRotaDoCanal(canal: any) {
    return (_: unknown, location: { pathname: string }) =>
        [
            `/canal/${canal.nomeDoCanal}`,
            `/canal/${canal.idDoCanalNoSlack}`,
        ].includes(location.pathname);
}

export const ListaDeCanaisItems = observer(
    ({ aoEscolherCanal }: PropriedadesDaListaDeCanaisItems) => {
        const { channels } = useMobxStores();

        const { loadingChannels, channels: channelsOfUser } = channels;

        if (loadingChannels) return (<li className="mb-4 md:text-white text-gray-500 text-sm">carregando...</li>)

        return (
            <>
                {channelsOfUser.map((canal: any) => (
                    <li
                        className="mb-4 md:text-white text-gray-500 text-sm"
                        key={canal.nomeDoCanal}
                    >
                        <NavLink
                            className="flex gap-2 items-center "
                            activeClassName="font-bold"
                            isActive={estaNaRotaDoCanal(canal)}
                            to={{
                                pathname: `/canal/${canal.nomeDoCanal}`,
                                state: { canal: { ...canal } },
                            }}
                            onClick={() => aoEscolherCanal(canal)}
                        >
                            {canal.projeto || canal.nomeDoCanal}
                        </NavLink>
                    </li>
                ))}
            </>
        );
    },
);