import React, { useState } from 'react';
import { LogOut } from 'react-feather';
import { useLayer } from 'react-laag';

import { observer } from 'mobx-react-lite';
import ResizeObserver from 'resize-observer-polyfill';

import { configuracoes } from '../../package.json';
import { useMobxStores } from '../hooks/useMobxStores';
import { DropdownItem } from './DropdownItem';

const Dropdown: React.FC = ({ children }) => {
  const { authenticator } = useMobxStores();

  function handleSignOut() {
    authenticator.signOut();
    window.location.href = configuracoes.urlDaXApps;
  }

  const [isOpen, setIsOpen] = useState(false);

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    onOutsideClick: () => setIsOpen(false),
    onDisappear: () => setIsOpen(false),
    overflowContainer: false,
    auto: true,
    placement: 'bottom-start',
    triggerOffset: 12,
    ResizeObserver,
    containerOffset: 16,
  });

  return (
    <>
      <span {...triggerProps} onClick={() => setIsOpen(!isOpen)}>
        {children}
      </span>

      {renderLayer(
        isOpen && (
          <ul className="bg-gray-300 rounded" {...layerProps}>
            <DropdownItem Icon={LogOut} label="Sair" onClick={handleSignOut} />
          </ul>
        ),
      )}
    </>
  );
}

const Component = observer(Dropdown);

export { Component as Dropdown };
