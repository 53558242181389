import { TipoDaMensagem } from './mensagem';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';
import get from 'lodash/get';
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import { useEffect } from 'react';
import React from 'react';

interface PropriedadesDosAnexosDaMensagem {
  mensagem: TipoDaMensagem;
}

export const IndicadorDeThread = observer(
  ({ mensagem }: PropriedadesDosAnexosDaMensagem) => {
    const { users: usuariosStore, messages } = useMobxStores();

    const { definirThreadAberta, obterMensagensThread, threadMensagens } =
      messages;

    if (!mensagem) return null;

    useEffect(() => {
      if (mensagem.thread_ts) {
        obterMensagensThread(mensagem.thread_ts);
      }
    }, [mensagem]);

    const nomeDoAutor = get(
      usuariosStore.users,
      `${mensagem.user!}.nome`,
      get(mensagem, 'username', get(mensagem, 'bot_profile.name')),
    );

    const thread: TipoDaMensagem = get(threadMensagens, [
      `${mensagem.thread_ts!}`,
      '0',
    ]);

    return (
      <div
        onClick={() => definirThreadAberta(thread)}
        className={`
        max-w-screen-sm rounded hover:bg-gray-200 border-1
        hover:border-gray-300 border-transparent
        cursor-pointer w-full p-1 flex align-center
      `}
      >
        {thread?.reply_users?.map((usuario, index) => (
          <img
            key={index}
            className="mr-2 inline-block w-6 h-6 min-h-6 min-w-6 rounded bg-gray-300"
            alt={`Avatar de ${nomeDoAutor}`}
            src={get(
              thread,
              'icons.image_48',
              get(usuariosStore.users, `${usuario!}.foto`, defaultAvatar),
            )}
            onError={(e: any) => {
              e.target.onerror = null;
              e.target.src = defaultAvatar;
            }}
          />
        ))}
        <div className="font-semibold text-blue-800 self-center">
          {`${thread?.reply_count} respostas`}
        </div>
      </div>
    );
  },
);
