import { IndicadorDeCarregamento } from "@xapps/design-system";

export const LoadingChannels = (): JSX.Element => {
    return (
        <div className="h-screen flex flex-col relative items-center justify-center">
            <div className="flex flex-row items-center">
                <IndicadorDeCarregamento tamanho={24} />
                <h2 className="text-gray-2 text-md ml-3">
                    carregando canais...
                </h2>
            </div>
        </div>
    )
}