import { authenticator } from './authenticator';
import { channels } from './channels';
import { messages } from './messages';
import { notificator } from './notificator';
import { openedEmails } from './openedEmails';
import { users } from './users';

const stores = {
  authenticator,
  channels,
  openedEmails,
  messages,
  users,
  notificator,
};

export { stores };
