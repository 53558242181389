import { Field, Form } from 'react-final-form';
import { Paperclip, Send } from 'react-feather';
import { useCallback, useRef } from 'react';

import { ArquivosAnexados } from './ArquivosAnexados';
import { BarraDeFormatacao } from './BarraDeFormatacao';
import { InputComMencoes } from './InputComMencoes';
import { ProgressoDoUpload } from './ProgressoDoUpload';
import classnames from 'classnames';
import { configuracoes } from '../../../package.json';
import { notificator } from '../../stores/notificator';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';

interface PropriedadesDoFormularioDaMensagem {
  canalSelecionado: any;
  aposEnviarMensagem(): void;
  formClass?: string;
}

export const FormularioDeMensagem = observer(
  ({
    canalSelecionado,
    aposEnviarMensagem,
    formClass = '',
  }: PropriedadesDoFormularioDaMensagem) => {
    const { messages } = useMobxStores();
    const { enviarMensagem, membrosDoCanal } = messages;
    const referenciaDoInput = useRef(null);
    const referenciaDoInputDeArquivos = useRef<HTMLInputElement | null>(null);

    const enviarMensagemProCanal = useCallback(
      (mensagem, arquivos) => {
        enviarMensagem(canalSelecionado?.idDoCanalNoSlack, mensagem, arquivos);
        aposEnviarMensagem();
      },
      [canalSelecionado, enviarMensagem, aposEnviarMensagem],
    );

    if (!canalSelecionado) return null;

    const membros = Object.values(membrosDoCanal)
      .filter((membro) => membro)
      .map((membro: any) => ({
        id: membro.id,
        display: membro.nome,
      }));

    return (
      <Form
        initialValues={{
          mensagem: '',
          arquivos: [],
        }}
        onSubmit={(values, form) => {
          enviarMensagemProCanal(values.mensagem, [...values.arquivos]);
          setTimeout(form.reset);
        }}
      >
        {({ handleSubmit, values: { mensagem, arquivos }, form }) => {
          const temAlgoPraEnviar = !!mensagem || arquivos.length;

          return (
            <form
              onSubmit={handleSubmit}
              className={`md:bottom-8 col-span-full shadow bottom-0
                sticky bg-white border-t border-gray-200
                flex flex-wrap md:flex-col ${formClass}`}
            >
              <Field name="mensagem">
                {({ input }) => (
                  <InputComMencoes
                    inputRef={referenciaDoInput}
                    placeholder={`Enviar para #${canalSelecionado?.nomeDoCanal}`}
                    aoPressionarEnter={form.submit}
                    membrosDoCanal={membros}
                    obterNomeDoMembro={(id) => `@${membrosDoCanal[id]?.nome}`}
                    input={input}
                  />
                )}
              </Field>

              {!!arquivos.length && (
                <div className="flex w-full text-gray-400 justify-between md:border-t md:py-2 py-4 px-4">
                  <ArquivosAnexados
                    removeArquivo={(index) =>
                      form.change('arquivos', [
                        ...arquivos.slice(0, index),
                        ...arquivos.slice(index + 1),
                      ])
                    }
                    arquivos={arquivos}
                  />
                </div>
              )}

              <div className="flex text-gray-400 justify-between md:border-t md:py-2 py-4 px-4 md:mr-2">
                <BarraDeFormatacao
                  alterarValor={(novoValor) =>
                    form.change('mensagem', novoValor)
                  }
                  referenciaDoInput={referenciaDoInput}
                />

                <div className="flex items-center">
                  <Field name={`arquivos`}>
                    {({ input }) => (
                      <input
                        type="file"
                        className="hidden"
                        ref={referenciaDoInputDeArquivos}
                        multiple
                        name={input.name}
                        onChange={(e) => {
                          if (
                            e.target.files &&
                            [...e.target.files].some(
                              (x) =>
                                x.size >
                                configuracoes.tamanhoMaximoDeUploadEmMB *
                                  1024 *
                                  1024,
                            )
                          ) {
                            notificator.erro(
                              `O tamanho máximo para envio de arquivos é de ${configuracoes.tamanhoMaximoDeUploadEmMB} MB`,
                            );
                          } else {
                            form.change('arquivos', [
                              ...arquivos,
                              ...(e.target.files ?? []),
                            ]);
                          }

                          e.target.value = '';
                        }}
                      />
                    )}
                  </Field>

                  <ProgressoDoUpload />

                  <Paperclip
                    onClick={() => referenciaDoInputDeArquivos.current!.click()}
                    className={classnames(
                      {
                        'text-gray-400': !temAlgoPraEnviar,
                        'text-blue-primary': temAlgoPraEnviar,
                      },
                      'inline-block cursor-pointer mr-4',
                    )}
                  />

                  <Send
                    className={classnames(
                      {
                        'text-gray-400': !temAlgoPraEnviar,
                        'text-blue-primary': temAlgoPraEnviar,
                      },
                      'cursor-pointer',
                    )}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    );
  },
);
