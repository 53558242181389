import 'firebase/messaging';

import { useEffect } from 'react';
import firebase from 'firebase/app';

import { useMobxStores } from '../hooks/useMobxStores';
import { API } from './API';

let messaging: firebase.messaging.Messaging;

try {
  firebase.initializeApp(JSON.parse(process.env.REACT_APP_CONFIGURACAO_FIREBASE!));
  messaging = firebase.messaging()
} catch {
}

export function usePushNotification() {
  const { notificator } = useMobxStores();

  useEffect(() => {
    if (messaging) {
      const remover = messaging.onMessage((payload) => {
        notificator.notificarMensagemRecebida(payload.data);
      });

      return remover;
    }
  }, [notificator])

  useEffect(() => {
    NotificacoesPush.registrarToken();
  }, []);
}

export const NotificacoesPush = {
  async registrarToken() {
    if (!("Notification" in window) || Notification.permission !== "granted" || !messaging) return

    const token = await messaging.getToken({ vapidKey: process.env.REACT_APP_CHAVE_VAPID })
    await API.fetch("/registrar-token-push", {
      method: "POST",
      body: JSON.stringify({ token }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
  }
}
