import jwtDecode from 'jwt-decode';
import { makeAutoObservable } from 'mobx';

import { api } from '../services/api';

interface IUser {
  id: string;
  name: string;
  email: string;
  avatar_url?: string;
}

class Authenticator {
  user?: IUser = undefined;
  isLoading: boolean = false;
  linkToLogin: string = '';

  constructor() {
    makeAutoObservable(this);

    this.loadUser();
  }

  get isLogged() {
    return !!this.user;
  }

  signIn() {
    window.location.href = process.env.REACT_APP_SSO_ENDPOINT!;
  }

  async signInWithQRCode() {
    try {
      this.isLoading = true;

      const response = await api.post('/login-por-url');

      if (response.status === 200) {
        const { link } = response.data;

        this.linkToLogin = link;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  loadUser() {
    const token = localStorage.getItem('xapps:chat@token');

    if (!token) return;

    const tokenDecodificado = jwtDecode(token) as any;

    tokenDecodificado &&
      (this.user = {
        id: tokenDecodificado.sub,
        email: tokenDecodificado.email,
        name: tokenDecodificado.nome || `${tokenDecodificado.data?.name} ${tokenDecodificado.data?.last_name}`,
        avatar_url:
          tokenDecodificado.urlDoAvatar ??
          tokenDecodificado.data?.image_url ??
          `${process.env.REACT_APP_URL_DA_API}/avatar?${new URLSearchParams([
            ['hash', tokenDecodificado.nome],
          ])}`,
      });
  }

  updateToken = (token: string) => {
    localStorage.setItem('xapps:chat@token', token);

    api.defaults.headers.Authorization = `Bearer ${token}`;

    this.loadUser();
  };

  expireSession() {
    if (!this.user) this.signIn();
    window.location.href = `${process.env.REACT_APP_SSO_ENDPOINT!}?email=${this.user!.email}`;
    // window.location.href = `${
    //   process.env.REACT_APP_URL_DO_PROVEDOR_DE_IDENTIDADE
    // }/sessao-expirada?${new URLSearchParams([
    //   ['email', this.user!.email],
    //   ['origem', 'Chat'],
    // ])}`;
  }

  signOut() {
    localStorage.removeItem('xapps:chat@token');
  }
}

const INSTANCE = new Authenticator();

export { Authenticator, INSTANCE as authenticator };
