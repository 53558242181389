import React from "react";
import zoomIcon from "../../assets/images/zoomIcon.png";
import googleMeetIcon from "../../assets/images/googleMeetIcon.png";
import wherebyIcon from "../../assets/images/wherebyIcon.png";
import formatDuration from "date-fns/formatDuration";
import ptBR from "date-fns/locale/pt-BR";

export enum SitesAudioVideo {
  Google = 'Google',
  Zoom = 'Zoom',
  Whereby = 'Whereby',
}

interface Props {
  link: string;
  site: SitesAudioVideo | null;
  duracaoAposEnvio: Duration;
}

export const MensagemDeAudioEVideo: React.FC<Props> = ({ link, site, duracaoAposEnvio }) => {
  const icone = React.useMemo(() => {
    return {
      [SitesAudioVideo.Whereby]: wherebyIcon,
      [SitesAudioVideo.Google]: googleMeetIcon,
      [SitesAudioVideo.Zoom]: zoomIcon,
    }
  }, []);

  const duracao = formatDuration(
    duracaoAposEnvio,
    {
      locale: ptBR,
      format: ['weeks', 'days', 'hours', 'minutes']
    }
  );

  const botaoEhVisivel = (
    !duracaoAposEnvio.weeks &&
    !duracaoAposEnvio.days &&
    (duracaoAposEnvio.hours || 0) < 4
  );

  return (
    <div className="max-w-sm">
      <div className={
        `bg-gray-200 p-3 border border-gray-300
        rounded-t ${botaoEhVisivel ? '' : 'rounded-b'} flex items-center`
      }>
        <img className="h-8 mr-3" src={site ? icone[site] : ''} alt={site as string} />
        <div>
          <div className="font-bold text-base break-normal">
            Reunião no {site}
          </div>
          <div className="break-normal">
            Link enviado há {duracao || 'menos de 1 minuto'}
          </div>
        </div>
      </div>
      { botaoEhVisivel &&
        <div className="flex items-center p-3 border rounded-b border-gray-300 break-normal">
          <a
            className="rounded bg-green-600 text-white font-bold cursor-pointer px-4 py-2"
            type="button"
            target="_blank"
            rel="noreferrer"
            href={link}
          >
            Entrar na reunião
          </a>
        </div>
      }
    </div>
  )
};