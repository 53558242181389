import React from 'react';
import { Menu } from 'react-feather';

import { formatChannelName } from '../utils/formatChannelName';

const Header: React.FC<any> = ({ alternarMenu, canalAtivo, canalSelecionado }) => {
  return (
    <header className="md:-mx-5 md:relative sticky top-0 md:h-20 h-14 md:shadow-none md:border-b-2 shadow-md md:bg-white bg-blue-primary md:text-blue-primary text-white py-2 z-10">
      <div className="md:mx-5 grid grid-cols-4 h-10 md:h-full gap-5 md:px-0 px-4 items-center">
        <button className="md:hidden" onClick={alternarMenu}>
          <Menu />
        </button>

        <div className="col-span-2 md:text-left text-center">
          <p className="font-bold">{formatChannelName(canalSelecionado?.nomeDoCanal)}</p>
        </div>

        {canalAtivo && (
          <div className="col-span-2 md:text-left text-center">
            <p className="font-bold">{canalAtivo.nomeDoCanal}</p>
          </div>
        )}
      </div>
    </header>
  );
}

export { Header };
