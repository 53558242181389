import React from 'react';
import Mensagem from '../chat/mensagem';
import { deixarPrimeiraLetraMaiuscula } from '../../utilitarios/deixarPrimeiraLetraMaiuscula';
import { areEqual } from 'react-window-dynamic';

export function ItemDaListaThread({
  index,
  data,
  mostrarIndicadorDeThread,
}: any) {
  const mensagem = data[index];
  if (mensagem.tipo === 'agrupador') return null;

  const agrupador = data.find(
    (msg: any, idx: number) => msg.tipo === 'agrupador' && idx > index,
  );

  return (
    <Mensagem
      mostrarIndicadorDeThread={mostrarIndicadorDeThread}
      mensagem={mensagem}
      textoDeHora={agrupador && deixarPrimeiraLetraMaiuscula(agrupador.titulo)}
      isThreadItem
    />
  );
}

export default React.memo(ItemDaListaThread, areEqual);
