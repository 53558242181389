import axios, { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import { apiXAppsID } from './apiXAppsID';

type IFailedRequestQueue = {
  onSuccess: (token: string) => void;
  onFailure: (err: AxiosError) => void;
};

let isRefreshing = false;
let failedRequestsQueue: IFailedRequestQueue[] = [];

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_DA_API,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('xapps:chat@token')}`,
  },
});

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      const originalConfig = error.config;

      if (!isRefreshing) {
        isRefreshing = true;

        const token = localStorage.getItem('xapps:chat@token');

        if (!token) return;

        const { email } = jwtDecode(token) as any;

        apiXAppsID
          .post('/api/login', { email })
          .then((response) => {
            if (response) {
              api.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
                'xapps:chat@token',
              )}`;

              failedRequestsQueue.forEach((request) =>
                request.onSuccess(
                  `${localStorage.getItem('xapps:chat@token')}`,
                ),
              );
              failedRequestsQueue = [];
            }
          })
          .catch((err) => {
            failedRequestsQueue.forEach((request) => request.onFailure(err));
            failedRequestsQueue = [];
          })
          .finally(() => {
            isRefreshing = false;
          });
      }

      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          onSuccess: (token: string) => {
            originalConfig.headers.Authorization = `Bearer ${token}`;

            resolve(api(originalConfig));
          },
          onFailure: (err: AxiosError) => {
            reject(err);
          },
        });
      });

      // Token inválido
    }

    return Promise.reject(error);
  },
);

export { api };
