import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";
import get from "lodash/get";

import defaultAvatar from "../assets/images/defaultAvatar.png";
import { notificarNativamente } from "../utilitarios/notificacoes";
import { Users, users } from "../stores/users";
import { authenticator, Authenticator } from "../stores/authenticator";

class Notificator {
  constructor(
    private readonly usuarios: Users,
    private readonly autenticador: Authenticator,
  ) {
    makeAutoObservable(this);
  }

  notificarMensagemRecebida = async (mensagem: any = {}) => {
    const imagem = get(
      mensagem,
      "icons.image_48",
      get(this.usuarios.users, `${mensagem.user!}.foto`, defaultAvatar)
    );
    const idDoCanalNoSlack = mensagem.channel;

    const nomeDoAutor = get(
      this.usuarios.users,
      `${mensagem.user!}.nome`,
      get(mensagem, "username", get(mensagem, "bot_profile.name"))
    );

    if (nomeDoAutor === this.autenticador.user?.name) return;

    const estaCensurado = nomeDoAutor === "Colaborador";

    notificarNativamente({
      titulo: `Mensagem recebida${nomeDoAutor ? ` de ${nomeDoAutor}` : ""}`,
      corpo: mensagem.text,
      imagem: estaCensurado ? defaultAvatar : imagem,
      canal: idDoCanalNoSlack,
    });
  };

  erro = (mensagem: string) => {
    toast.error(mensagem, {});
  };

  info = (mensagem: string) => {
    toast.info(mensagem, {});
  }
}

const INSTANCE = new Notificator(users, authenticator);

export { Notificator, INSTANCE as notificator };
