function formatChannelName(channelName: string) {
  let formattedChannelName: string = '';

  if (!channelName || channelName === '') {
    return 'X-Apps Chat'
  }

  channelName.split('-').forEach((p) => {
    formattedChannelName += p.charAt(0).toUpperCase() + p.slice(1) + ' ';
  })

  return formattedChannelName;
}

export { formatChannelName };
