export function notificarNativamente({
  titulo,
  corpo,
  imagem,
  canal,
}: {
  titulo: string;
  corpo?: string;
  imagem?: string;
  canal: {
    idDoCanalNoSlack: string
  } | undefined
}) {
  const notificar = () => {
    const notificacao = new Notification(titulo, { body: corpo, icon: imagem });
    notificacao.onclick = () => {
      canal ? window.location.pathname = `/canal/${canal.idDoCanalNoSlack}` : window.focus();
      notificacao.close();
    };
  };

  if (!("Notification" in window)) return;
  else if (Notification.permission === "granted") {
    notificar();
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        notificar();
      }
    });
  }
}
