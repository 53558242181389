import { API } from "../../utilitarios/API";
import { memo, ReactNode, useCallback, useEffect, useState } from "react";
import classnames from "classnames"

interface PropriedadesDoDownloadDaApi {
  url: string;
  className?: string;
  children(base64: string, carregando: boolean): ReactNode;
  lazy?: boolean;
  nomeDoArquivo: string;
}

export const DownloadDaApi = memo(
  ({ url, children, nomeDoArquivo, lazy }: PropriedadesDoDownloadDaApi) => {
    const [base64, definirBase64] = useState<string>("");
    const [carregando, definirCarregando] = useState(false)

    const obter = useCallback(async () => {
      definirCarregando(true)
      const response = await API.fetch(url);
      const blob = await response.blob()
      definirCarregando(false)
      return URL.createObjectURL(blob);
    }, [url]);

    async function baixarNoNavegador() {
      definirCarregando(true)
      let a = document.createElement("a");
      a.href = await obter();
      a.download = nomeDoArquivo;
      a.click();
    }

    useEffect(() => {
      if (lazy) return;
      let montado = true;

      obter().then(async (resultado) => {
        if (montado) {
          definirBase64(resultado)
        }
      });
      return () => { montado = false };
    }, [url, definirBase64, lazy, obter]);

    return (
      <a
      className={
        classnames({
          "cursor-pointer": !carregando,
          "cursor-wait pointer-events-none": carregando,
        })
      }
        onClick={lazy ? baixarNoNavegador : undefined}
        href={lazy ? undefined : base64}
        download={!lazy}
      >
        {children(base64, carregando)}
      </a>
    );
  }
);
