import React from 'react';
import QRCode from 'qrcode.react';
import { IndicadorDeCarregamento } from '@xapps/design-system';

interface Props {
  qrCodeValue: string;
  carregando: boolean;
}

export const CodigoQRModal: React.FC<Props> = ({ qrCodeValue, carregando }) => {
  return (
    <div className="px-12 py-16 flex">
      <div className="text-gray-2 max-w-md">
        <div className="text-blue-primary font-bold text-2xl pb-6">Para usar o chat X-Apps no celular:</div>
        <div className="pb-6"><strong>01.</strong> Abra a câmera do seu celular e aponte para a imagem ao lado.</div>
        <div className="pb-6"><strong>02.</strong> Uma notificação com um link irá aparecer na sua tela.</div>
        <div className="pb-6"><strong>03.</strong> Clique na notificação e você será direcionado ao chat.</div>
      </div>
      { carregando
        ? <IndicadorDeCarregamento
          tamanho={240}
          className="stroke-current ml-16"
        />
        : <QRCode
          className="ml-16"
          size={240}
          fgColor="#4F4F4F"
          value={qrCodeValue}
        />
      }
    </div>
  )
}