import { TipoDaMensagem } from './mensagem';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';
import get from 'lodash/get';
import { useCallback, useEffect } from 'react';
import React from 'react';

interface ReferenciadorDeThreadProps {
  mensagem: TipoDaMensagem;
}

export const ReferenciadorDeThread = observer(
  ({ mensagem }: ReferenciadorDeThreadProps) => {
    const { messages } = useMobxStores();

    const { definirThreadAberta, obterMensagensThread, threadMensagens } =
      messages;

    if (!mensagem) return null;

    useEffect(() => {
      if (mensagem.thread_ts) {
        obterMensagensThread(mensagem.thread_ts).then();
      }
    }, [mensagem]);

    const thread: TipoDaMensagem = get(threadMensagens, [
      `${mensagem.thread_ts!}`,
      '0',
    ]);

    const openThread = useCallback(() => {
      definirThreadAberta(thread);
    }, [thread, definirThreadAberta]);

    return (
      <div className="-mt-2">
        <span className="text-gray-2 text-xs">respondeu em uma conversa: </span>
        <span
          onClick={openThread}
          className="text-xs text-blue-primary font-bold cursor-pointer select-none hover:underline underline-offset-1"
        >
          mensagem
        </span>
      </div>
    );
  },
);
