import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { useMobxStores } from './hooks/useMobxStores';
import { apiXAppsID } from './services/apiXAppsID';

const TEMPO_PARA_REGISTRAR_O_ACESSO = 7000;

function useRegistroDeUltimoAcesso() {
  const { authenticator } = useMobxStores();

  const { isLogged } = authenticator;

  useEffect(() => {
    registrarUltimoAcesso();

    const intervalo = setInterval(() => {
      if (isLogged) registrarUltimoAcesso();
    }, TEMPO_PARA_REGISTRAR_O_ACESSO);

    return () => clearInterval(intervalo);
  }, [isLogged]);
}

export const RegistrarUltimoAcesso = observer(() => {
  useRegistroDeUltimoAcesso();

  return null;
});

function registrarUltimoAcesso() {
  apiXAppsID.post('/api/usuario/registrar-ultimo-acesso');
}
