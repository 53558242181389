import { makeAutoObservable } from 'mobx';
import keyBy from 'lodash/keyBy';

import { api } from '../services/api';

class Users {
  users: { [key: string]: any } = {};

  constructor() {
    makeAutoObservable(this);
  }

  getUsers = async () => {
    const resposta = await api.get(`/usuarios`);

    if (resposta.status === 200) {
      const usuarios = resposta.data;
      this.users = keyBy(usuarios.usuarios, 'id');
    }
  };
}

const INSTANCE = new Users();

export { Users, INSTANCE as users };
