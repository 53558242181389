import { makeAutoObservable } from 'mobx';

class OpenedEmails {
  openeds: Record<string, boolean> = {};

  constructor() {
    makeAutoObservable(this);
  }

  definirEmailAberto = (id: string, valor: boolean) => {
    this.openeds[id] = valor;
  };
}

const INSTANCE = new OpenedEmails();

export { OpenedEmails, INSTANCE as openedEmails };
