import { useMediaQuery } from "react-responsive";

export function useEstiloDoComponente() {
  const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  const height = isMd ? "4rem" : "100%";
  return {
    "&multiLine": {
      marginRight: "0.5rem",
      width: isMd ? "100%" : "calc(100% - 104px)",

      control: {
        fontSize: "0.875rem",
      },

      highlighter: {
        overflowY: "auto",
        padding: "1rem",
        height,
      },
      input: {
        overflowY: "auto",
        padding: "1rem",
        fontSize: "1rem",
        outline: "none",
        height,
      }
    },

    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
      },
      item: {
        padding: "0.5rem",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "rgba(219, 234, 254, 0.8)",
        },
      },
    },
  };
}

export const estiloDaMencao = {
  backgroundColor: "rgba(219, 234, 254, 0.8)",
};
