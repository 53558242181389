import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { Home } from '../pages/Home';
import { LoginCallback } from '../pages/LoginCallback';

const Routes: React.FC = () => {
  return (
    <Router>
      <Route path="/" component={Home} />
      <Route path="/login" component={LoginCallback} />
    </Router>
  );
}

export { Routes };
