import React from "react";
import { X } from "react-feather";

interface PropriedadesDosArquivosAnexados {
  arquivos: FileList;
  removeArquivo(index: number): void;
}

export function ArquivosAnexados({
  arquivos,
  removeArquivo,
}: PropriedadesDosArquivosAnexados) {
  return (
    <div className="flex-1 grid grid-cols-1 md:grid-cols-3 gap-2">
      {[...arquivos].map((arquivo, index) => (
        <div
          key={`arquivo-anexado-${index}`}
          className="flex items-center justify-between bg-gray-100 py-2 px-4 "
        >
          <span className="w-32 truncate text-sm">{arquivo.name}</span>
          <X
            className="cursor-pointer ml-2 w-4 h-4"
            onClick={() => removeArquivo(index)}
          />
        </div>
      ))}
    </div>
  );
}
