import Mensagem from '../chat/mensagem';
import React, { forwardRef } from 'react';
import { areEqual } from 'react-window-dynamic';
import { deixarPrimeiraLetraMaiuscula } from '../../utilitarios/deixarPrimeiraLetraMaiuscula';

export const ItemDaLista = forwardRef(
  ({ mensagem, style }: any, ref: any) => {
    if (mensagem.tipo === 'agrupador')
      return (
        <li style={style} ref={ref}>
          <div
            className={`text-gray-400 text-sm font-bold
            border-gray-400 py-2 border-b`}
          >
            {deixarPrimeiraLetraMaiuscula(mensagem.titulo)}
          </div>
        </li>
      );

    return (
      <li style={style} ref={ref}>
        <Mensagem
          hoverNaMensagem
          mostrarIndicadorDeThread
          atualizarAltura={() => {}}
          mensagem={mensagem.message ? mensagem.message : mensagem}
        />
      </li>
    );
  },
);

export default React.memo(ItemDaLista, areEqual);
