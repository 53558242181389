import { io } from 'socket.io-client';

export const getSocket = () => io(process.env.REACT_APP_URL_DA_API!, {
  transports: ['websocket'],
  secure: false,
  rejectUnauthorized: false,
  extraHeaders: {
    Authorization: `Bearer ${localStorage.getItem('xapps:chat@token')}`,
  },
});
