import { useCallback, useEffect } from "react";

export function useNotificationPermissionRequest() {
  const obterPermissaoDeNotificacao = useCallback(async () => {
    if (!("Notification" in window)) {
      return;
    }

    await Notification.requestPermission();
  }, []);

  useEffect(() => {
    obterPermissaoDeNotificacao();
  }, []);
}
