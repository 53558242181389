import axios from 'axios';

const apiXAppsID = axios.create({
  baseURL: process.env.REACT_APP_URL_DO_PROVEDOR_DE_IDENTIDADE
});

apiXAppsID.interceptors.request.use(async (config: any) => {
  // resolve authorization token injection
  const jwt = localStorage.getItem('xapps:chat@token')
  if (jwt && !config.headers.XPreventAuth) config.headers.Authorization = `Bearer ${jwt}`;
  return config;
});


export { apiXAppsID };
