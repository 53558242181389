import React from 'react';
import { ChevronDown, X } from 'react-feather';

import { useMobxStores } from '../hooks/useMobxStores';
import defaultAvatar from '../assets/images/defaultAvatar.png';
import { Dropdown } from './Dropdown';

interface UserMenuProps {
  onClose(): void;
}

const UserMenu: React.FC<UserMenuProps> = ({ onClose }) => {
  const { authenticator } = useMobxStores();

  const { user }= authenticator;

  return (
    <header className="bg-blue-primary">
      <div className="grid grid-cols-4 gap-5 md:px-5 px-4">
        <div className="col-span-full py-4 ">
          {user && (
            <Dropdown>
              <div className="flex-1 flex items-center cursor-pointer">
                <figure>
                  <img
                    alt="avatar do usuário logado"
                    className="w-10 rounded"
                    src={user.avatar_url ? user.avatar_url : defaultAvatar}
                  />
                </figure>
                <p className="text-white ml-2 text-sm font-bold">
                  {user.name}
                </p>

                <ChevronDown className="ml-2 text-white" />

                <button
                  type="button"
                  className="md:hidden block ml-auto text-white"
                  onClick={onClose}
                >
                  <X />
                </button>
              </div>
            </Dropdown>
          )}
        </div>
      </div>
    </header>
  );
};

export { UserMenu };
