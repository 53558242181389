import { useMediaQuery } from 'react-responsive';

function useMediaQueries() {
  const isDesktop = useMediaQuery({ minWidth: '56em' });
  const isTablet = useMediaQuery({ maxWidth: '895px', minWidth: '40em' });
  const isMobile = useMediaQuery({ maxWidth: '639px' });

  return { isDesktop, isTablet, isMobile };
}

export { useMediaQueries };
