import { forwardRef, useEffect, useRef, useState } from 'react';
import ItemDaLista from './ItemDaLista';
import { IndicadorDeCarregamentoDaLista } from './IndicadorDeCarregamentoDaLista';

if (typeof ResizeObserver === 'undefined') {
  global.ResizeObserver = require('resize-observer-polyfill').default;
}

interface PropriedadesDaListaDeMensagens {
  listaDeMensagens: any[];
  obterProximaPagina(): void;
  paginasCarregadas: number;
  canal: any;
  carregando: boolean;
}

const ListaDeMensagens = forwardRef(
  (
    {
      listaDeMensagens,
      obterProximaPagina,
      paginasCarregadas,
      canal,
      carregando,
    }: PropriedadesDaListaDeMensagens,
    ref: any,
  ) => {
    // const [proximaPagina, setProximaPagina] = useState(true)
    // const [localDoScroll, setLocalDoScroll] = useState(-1)

    const handleScroll = (event: any) => {
      if (event?.target?.scrollTop === 0 && !carregando) {
        console.log("DENTRO");
        // setProximaPagina(false)
        obterProximaPagina();
      }
    };

    useEffect(() => {
       if ( listaDeMensagens.length && paginasCarregadas) {
        if(ref?.current){
          ref.current.scrollIntoView({behavior: 'smooth'})
        } else {
          const altura = document.getElementById('chatBox')?.scrollHeight
          document.getElementById('chatBox')?.scrollTo({ top: altura, behavior: "smooth" })
        }
       }
    }, [listaDeMensagens, paginasCarregadas, ref]);

    return (
      <div className="relative md:mb-8 z-0 md:h-auto h-full flex-1">
        <div className="md:block grid grid-cols-4 gap-5 md:px-0 px-4 items-center h-full break-all">
          {!paginasCarregadas ? (
            <IndicadorDeCarregamentoDaLista />
          ) : (
            <ul className="col-span-full h-full flex-auto">
              <div id='chatBox' onScroll={handleScroll} className="chatBox" style={{height: 'calc(100vh - 219px)', overflow: 'auto'}}>
              {
                listaDeMensagens.map((item, key) => <ItemDaLista mensagem={item} key={key}/>)
              }
              <div ref={ref}/>

                  {/* <List
                    ref={ref}
                    width={window.innerWidth}
                    height={window.innerHeight}
                    className="scrollbar-none"
                    // (initialScrollOffset, onScroll, rolagemInicial e canal no useEffect) são utilizados
                    // juntos como hack para rolar a página para o final da lista onde o initialScrollOffset
                    // faz a rolagem máxima que a lista vitualizada consegue no mount dela e o onScroll que
                    // sempre é ativado ao scrollar identifica e força uma outra rolagem nesse segundo render
                    initialScrollOffset={distancia}
                    itemData={listaDeMensagens}
                    itemCount={listaDeMensagens.length}
                    onItemsRendered={({
                      visibleStartIndex,
                    }: {
                      visibleStartIndex: number;
                    }) => {
                      if (visibleStartIndex === 0 && !carregando) obterProximaPagina();
                    }}
                  >
                    {ItemDaLista}
                  </List> */}
              </div>
            </ul>
          )}
        </div>
      </div>
    );
  },
);

export default ListaDeMensagens;
