import React from 'react';
import { FieldInputProps } from 'react-final-form';
import { Mention, MentionsInput } from 'react-mentions';
import { useMediaQueries } from '../../hooks/useMediaQueries';
import { estiloDaMencao, useEstiloDoComponente } from './estiloDoInputDeMencao';

interface PropriedadesDoInputComMencoes {
  input: FieldInputProps<any, HTMLElement>;
  inputRef: any;
  membrosDoCanal: {
    id: string;
    display: string;
  }[];
  aoPressionarEnter(): void;
  placeholder: string;
  obterNomeDoMembro(id: string): string;
}

export const InputComMencoes = ({
  input,
  inputRef,
  membrosDoCanal,
  aoPressionarEnter,
  obterNomeDoMembro,
  placeholder,
}: PropriedadesDoInputComMencoes) => {
  const estiloDoComponente = useEstiloDoComponente();
  const { isMobile } = useMediaQueries();

  return (
    <MentionsInput
      style={estiloDoComponente}
      inputRef={inputRef}
      className="input-de-mencoes"
      autoFocus
      placeholder={placeholder}
      allowSuggestionsAboveCursor
      type="text"
      autoComplete="off"
      onKeyDown={(e: any) => {
        if (e.keyCode === 13 && e.shiftKey === false && !isMobile) {
          e.preventDefault();
          aoPressionarEnter();
        }
      }}
      {...input}
    >
      <Mention
        trigger="@"
        markup="<@__id__>"
        style={estiloDaMencao}
        displayTransform={obterNomeDoMembro}
        data={membrosDoCanal}
      />
    </MentionsInput>
  );
};
