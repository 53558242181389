import { useMobxStores } from '../hooks/useMobxStores';
import { useParams } from 'react-router-dom';

export function useCanalAtivo() {
  const { channels } = useMobxStores();
  const { channels: channelsOfUser } = channels;
  const { nomeDoCanal } = useParams<any>();

  return channelsOfUser.find(channel => channel.nomeDoCanal === nomeDoCanal);
}
