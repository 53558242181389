import filter from "lodash/filter";
import get from "lodash/get";
import reject from "lodash/reject";
import { Anexo } from "./Anexo";
import { ehImagem } from "./ImagensDaMensagem";
import { TipoDaMensagem } from "./mensagem";
import EmailAnexo from "./EmailAnexo";
import { memo } from "react";

export interface PropriedadesDosArquivosAnexosDaMensagem {
  mensagem: TipoDaMensagem;
  atualizarAltura(): void;
}

interface Email {
  original: string;
}

export interface Arquivo {
  to: Email[];
  preview_plain_text: string;
  preview: string;
  from: Email[];
  title: string;
  id: string;
  filetype: "email" | string;
}

export const ArquivosAnexosDaMensagem = memo(
  ({ mensagem, atualizarAltura }: PropriedadesDosArquivosAnexosDaMensagem) => {
    const arquivos = get(mensagem, "files", []);
    const ehAnexoComPreview = (arquivo: Arquivo) =>
      ehImagem(arquivo.filetype) || arquivo.filetype === "email";

    if (!arquivos.length) return null;

    const emails = filter(arquivos, (arquivo) => arquivo.filetype === "email");
    const outrosAnexos = reject(arquivos, ehAnexoComPreview);

    return (
      <div className="grid grid-cols-3 gap-2 mb-2">
        {emails.map((arquivo: Arquivo) => (
          <EmailAnexo
            aposAbrirEmail={atualizarAltura}
            key={arquivo.id}
            arquivo={arquivo}
          />
        ))}

        {outrosAnexos.map((arquivo: any) => (
          <Anexo key={arquivo.id} arquivo={arquivo} />
        ))}
      </div>
    );
  }
);
