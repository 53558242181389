/* eslint-disable jsx-a11y/alt-text */
import './markdown.css';

import {
  MensagemDeAudioEVideo,
  SitesAudioVideo,
} from './MensagemDeAudioEVideo';
import {
  extraiLinkAudioVideo,
  extraiSiteDoLink,
  validaLinkAudioVideo,
} from '../../utilitarios/linkAudioVideo';

import { API } from '../../utilitarios/API';
import { AnexosDaMensagem } from './AnexosDaMensagem';
import { ArquivosAnexosDaMensagem } from './ArquivosAnexosDaMensagem';
import { ImagensDaMensagem } from './ImagensDaMensagem';
import { IndicadorDeCarregamento } from '@xapps/design-system';
import { IndicadorDeFalha } from './IndicadorDeFalha';
import { IndicadorDeThread } from './IndicadorDeThread';
import { MessageCircle } from 'react-feather';
import React from 'react';
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import classNames from 'classnames';
import { configuracoes } from '../../../package.json';
import { deixarPrimeiraLetraMaiuscula } from '../../utilitarios/deixarPrimeiraLetraMaiuscula';
import format from 'date-fns/format';
import get from 'lodash/get';
import intervalToDuration from 'date-fns/intervalToDuration';
import { observer } from 'mobx-react-lite';
import { renderToString } from 'react-dom/server';
import { toHTML } from 'slack-markdown';
import { useMobxStores } from '../../hooks/useMobxStores';
import { ReferenciadorDeThread } from './ReferenciadorDeThread';

export interface TipoDaMensagem {
  text: string;
  ts: number;
  username?: string;
  user?: string;
  icons?: {
    image_48?: string;
  };
  thread_ts?: number;
  reply_users?: string[];
  reply_count?: number;
  attachments?: TipoDaMensagem[];
  blocks: {
    type: string;
  };
  message?: TipoDaMensagem;
  sending?: boolean;
  failed?: boolean;
  retry?(): void;
  subtype?: string;
}

const Avatar = observer(({ nomeDoAutor, mensagem }: any) => {
  const { users: usuariosStore } = useMobxStores();

  const enderecoDoAvatar = get(
    mensagem,
    'icons.image_48',
    get(usuariosStore.users, `${mensagem.user!}.foto`),
  );

  const estaCensurado = nomeDoAutor === 'Colaborador';

  if (!enderecoDoAvatar || estaCensurado)
    return (
      <img
        className="mr-2 inline-block w-10 h-10 min-h-10 min-w-10 rounded"
        src={API.obterURLDoAvatar(nomeDoAutor)}
      />
    );

  return (
    <img
      className="mr-2 inline-block w-10 h-10 min-h-10 min-w-10 rounded bg-gray-300"
      alt={`Avatar de ${nomeDoAutor}`}
      src={enderecoDoAvatar}
      onError={(e: any) => {
        e.target.onerror = null;
        e.target.src = defaultAvatar;
      }}
    />
  );
});

interface PropriedadesDaMensagem {
  mensagem: TipoDaMensagem;
  atualizarAltura?(): void;
  textoDeHora?: string;
  mostrarIndicadorDeThread?: boolean;
  hoverNaMensagem?: boolean;
  isThreadItem?: boolean;
}

export const Mensagem = observer(
  ({
    mensagem,
    atualizarAltura,
    mostrarIndicadorDeThread,
    hoverNaMensagem = false,
    textoDeHora = '',
    isThreadItem = false,
  }: PropriedadesDaMensagem) => {
    const [mostrarIconeDeThread, setMostrarIconeDeThread] =
      React.useState(false);
    const { users: usuariosStore, messages } = useMobxStores();

    const { definirThreadAberta } = messages;

    const processarMensagem = (texto: string) =>
      toHTML(texto, {
        slackCallbacks: {
          user: ({ id }: { id: string }) =>
            renderToString(
              <span className="font-bold bg-blue-100 bg-opacity-80">{`@${get(
                usuariosStore.users,
                `${id}.nome`,
                configuracoes.nomePadraoDoColaborador,
              )}`}</span>,
            ),
        },
      });

    const textoDaMensagem = get(mensagem, 'blocks[0].text.text', mensagem.text);
    const ehLinkAudioVideo = validaLinkAudioVideo(textoDaMensagem);

    const mensagemFormatadaSemLink = ehLinkAudioVideo
      ? textoDaMensagem.replace(extraiLinkAudioVideo(textoDaMensagem), '')
      : textoDaMensagem;

    const mensagemProcessada = processarMensagem(
      // Remove caracteres especiais que ficaram no pré processamento da mensagem
      mensagemFormatadaSemLink.replace(/<>|\*<>\*|\*\*/, ''),
    );

    const nomeDoAutor = get(
      usuariosStore.users,
      `${mensagem.user!}.nome`,
      get(mensagem, 'username', get(mensagem, 'bot_profile.name')),
    );

    const temThread = React.useMemo(
      () => !!mensagem.thread_ts && mostrarIndicadorDeThread,
      [mensagem.thread_ts, mostrarIndicadorDeThread],
    );

    const siteAudioVideo = ehLinkAudioVideo
      ? (deixarPrimeiraLetraMaiuscula(
          extraiSiteDoLink(extraiLinkAudioVideo(textoDaMensagem)),
        ) as SitesAudioVideo)
      : null;

    const tempoEmDuracao = intervalToDuration({
      start: new Date(mensagem.ts * 1000),
      end: new Date(),
    });

    const estaEnviando = mensagem.sending || false;
    const houveFalhaNaMensagem = mensagem.failed || false;

    const isThreadMessageAtMain = mensagem?.subtype === 'thread_broadcast';

    return (
      <div
        className={classNames(
          'max-w-screen-lg py-2 w-full relative whitespace-nowrap flex',
          {
            'hover:bg-gray-100': !temThread && hoverNaMensagem,
            'opacity-10': estaEnviando,
          },
        )}
        onMouseEnter={() =>
          mostrarIndicadorDeThread && setMostrarIconeDeThread(true)
        }
        onMouseLeave={() =>
          mostrarIndicadorDeThread && setMostrarIconeDeThread(false)
        }
      >
        <Avatar nomeDoAutor={nomeDoAutor} mensagem={mensagem} />
        <div className="w-full inline-flex flex-col">
          {houveFalhaNaMensagem && (
            <IndicadorDeFalha aoTentarNovamente={mensagem.retry} />
          )}
          <span className="text-blue-primary font-bold text-sm">
            {estaEnviando ? 'Enviando...' : nomeDoAutor}
            {estaEnviando && (
              <>
                <IndicadorDeCarregamento
                  className="inline-block"
                  tamanho={16}
                />
              </>
            )}
            <span className="text-gray-600 text-xs font-normal ml-2">
              {!!textoDeHora
                ? textoDeHora
                : format(new Date(mensagem.ts * 1000), "h'h'mm")}
            </span>
          </span>
          {isThreadMessageAtMain && !isThreadItem && (
            <ReferenciadorDeThread mensagem={mensagem} />
          )}
          <div className="whitespace-normal text-sm text-gray-800">
            <ImagensDaMensagem mensagem={mensagem} />
            <ArquivosAnexosDaMensagem
              atualizarAltura={atualizarAltura!}
              mensagem={mensagem}
            />
            {
              <div
                className="markdown"
                dangerouslySetInnerHTML={{ __html: mensagemProcessada }}
              />
            }
            {ehLinkAudioVideo && (
              <MensagemDeAudioEVideo
                link={extraiLinkAudioVideo(textoDaMensagem)}
                site={siteAudioVideo}
                duracaoAposEnvio={tempoEmDuracao}
              />
            )}

            {!ehLinkAudioVideo && (
              <AnexosDaMensagem
                mensagem={mensagem}
                processarMensagem={processarMensagem}
              />
            )}
            {!temThread && mostrarIconeDeThread && !isThreadMessageAtMain && (
              <div
                onClick={() => definirThreadAberta(mensagem)}
                className={`bg-gray-50 border-gray-200 border-2
                  cursor-pointer absolute right-2 -top-4
                  py-2 px-4 rounded-lg`}
              >
                <div className="flex items-center space-x-2">
                  <MessageCircle />
                  <div className="ml-0.5">Responder mensagem</div>
                </div>
              </div>
            )}
            {temThread && !isThreadMessageAtMain && (
              <IndicadorDeThread mensagem={mensagem} />
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default React.memo(Mensagem);
