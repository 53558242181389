import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import { useModal } from '@xapps/design-system';

import { SideBar } from '../components/SideBar';
import { ListaDeCanais } from '../componentes/canais';
import { CodigoQRModal } from '../componentes/chat/CodigoQRModal';
import { UserMenu } from '../components/UserMenu';

import { useMobxStores } from '../hooks/useMobxStores';
import { useNotificationPermissionRequest } from '../utilitarios/useNotificationPermissionRequest';

import { Messages } from './Messages';
import { VersionControl } from '../componentes/version-control/index';

const Home: React.FC = () => {
  const { channels, users, authenticator, messages } = useMobxStores();

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const { channels: channelsOfUser } = channels;

  useNotificationPermissionRequest();

  if (!authenticator.isLogged && location.pathname !== '/login') authenticator.signIn();

  useEffect(() => {
    if (authenticator.isLogged) {
      channels.getChannels();
      users.getUsers();
    }
  }, [authenticator.isLogged, channels, users]);

  useEffect(() => {
    channelsOfUser.length &&
      match.isExact &&
      history.push(`/canal/${channelsOfUser[0].nomeDoCanal}`);
  }, [channelsOfUser, history, match.isExact]);

  const [modal, openModal] = useModal(
    <CodigoQRModal qrCodeValue={authenticator.linkToLogin} carregando={authenticator.isLoading} />,
  );

  const mostraCodigoQRModal = () => {
    authenticator.signInWithQRCode();
    openModal();
  };

  return (
    <div className="md:overflow-hidden max-w-screen flex gap-5 px-5">
      {modal}

      <SideBar isOpenMenu={isOpenMenu}>
        <UserMenu onClose={() => setIsOpenMenu(false)} />
        <ListaDeCanais
          mostrarModalQRCode={mostraCodigoQRModal}
          aoEscolherCanal={(canal) => {
            setIsOpenMenu(false);
            messages.limparMensagensNaoLidas(canal);
          }}
        />
        <VersionControl/>
      </SideBar>

      <div className="w-screen md:w-full -mx-5 md:mx-0">
        <Switch>
          <Route
            path="/canal/:nomeOuIdDoCanal"
            render={(props) => (
              <Messages
                alternarMenu={() => setIsOpenMenu((atual) => !atual)}
                {...props}
              />
            )}
          />
        </Switch>
      </div>
    </div>
  );
}

const Component = observer(Home);

export { Component as Home };
