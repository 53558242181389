import * as React from 'react';

import { stores } from '../stores';
import { StoresContext } from '../contexts/StoresContext';

function useMobxStores() {
  return React.useContext<typeof stores>(StoresContext);
}

export { useMobxStores };
