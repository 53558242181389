import 'react-toastify/dist/ReactToastify.css';

import HTTPSRedirect from 'react-https-redirect';
import { ToastContainer } from 'react-toastify';

import { stores } from './stores';
import { getSocket } from './services/socket';

import { RegistrarUltimoAcesso } from './RegistrarUltimoAcesso';

import { StoreProvider } from './contexts/StoresContext';
import { usePushNotification } from './utilitarios/notificacoesPush';

import { Routes } from './routes';

function App() {
  usePushNotification();

  getSocket();

  return (
    <HTTPSRedirect>
      <StoreProvider value={stores}>
        <RegistrarUltimoAcesso />

        <Routes />

        <ToastContainer />
      </StoreProvider>
    </HTTPSRedirect>
  );
}

export default App;
