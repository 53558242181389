import React from "react"

type Props = {
    channelName: string
}

export const EmptySelectedChannel = (props: Props) => {
    return (
        <div className="h-screen flex flex-col relative items-center justify-center">
            <h1 className="text-blue-primary text-xl">
                O canal <strong>{props.channelName}</strong> não pode ser exibido
            </h1>
            <h2 className="text-gray-2 text-md mt-1">
                Para mais informações, entre em contato com nossa equipe
            </h2>
        </div>
    );
}