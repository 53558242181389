import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import queryString from 'query-string';

import { useMobxStores } from '../hooks/useMobxStores';

const LoginCallback: React.FC = () => {
  const { authenticator } = useMobxStores();

  const { updateToken } = authenticator;
  
  const { token } = queryString.parse(useLocation().search) as { token: string };

  updateToken(token);

  return <Redirect to="/" />;
}

export { LoginCallback };
