import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Header } from '../components/Header';
import { useMobxStores } from '../hooks/useMobxStores';
import { useCanalAtivo } from '../utilitarios/useCanalAtivo';
import { MessagesContent } from '../components/MessagesContent';
interface MessagesProps {
  alternarMenu(): void;
}

const Messages: React.FC<MessagesProps> = ({ alternarMenu }) => {

  const { channels } = useMobxStores();
  const { channels: channelsOfUser } = channels;
  const { nomeOuIdDoCanal: nomeDoCanal } = useParams<{ nomeOuIdDoCanal: string }>();
  const canalAtivo = useCanalAtivo();
  const canalSelecionado = channelsOfUser.find((channel: any) =>
    [channel.nomeDoCanal, channel.idDoCanalNoSlack].includes(nomeDoCanal),
  );

  return (
    <div className="min-h-screen h-screen flex flex-col relative">
      <Header {...{ alternarMenu, canalAtivo, canalSelecionado }} />
      <MessagesContent canalSelecionado={canalSelecionado} nomeDoCanal={nomeDoCanal}/>
    </div>
  );
};

const Component = observer(Messages);

export { Component as Messages };
