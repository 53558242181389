interface PropriedadesDoIndicadorDeFalha {
  aoTentarNovamente?: () => void;
}

export function IndicadorDeFalha({
  aoTentarNovamente,
}: PropriedadesDoIndicadorDeFalha) {
  return (
    <div>
      <button
        onClick={aoTentarNovamente}
        className="py-0.5 px-1 inline-block w-auto rounded text-red-300 bg-red-100 font-bold text-sm"
      >
        Falhou (Clique aqui para reenviar)
      </button>
    </div>
  );
}
