import React from "react";
import { IndicadorDeCarregamento } from "@xapps/design-system";
import "./IndicadorDeCarregamentoDaLista.css";

export function IndicadorDeCarregamentoDaLista() {
  return (
    <ul className="col-span-full my-4 justify-self-center w-full h-full flex items-center justify-center">
      <IndicadorDeCarregamento tamanho={60} />
    </ul>
  );
}
