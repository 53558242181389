import filter from "lodash/filter";
import get from "lodash/get";
import queryString from "query-string";
import { memo } from "react";
import { DownloadDaApi } from "./DownloadDaApi";
import { TipoDaMensagem } from "./mensagem";

export interface PropriedadesDasImagensDaMensagem {
  mensagem: TipoDaMensagem;
}

export function ehImagem(extensao: string) {
  return ["png", "jpg", "gif"].includes(extensao?.toLowerCase());
}

export const ImagensDaMensagem = memo(
  ({ mensagem }: PropriedadesDasImagensDaMensagem) => {
    const arquivos = get(mensagem, "files", []);
    if (!arquivos.length) return null;

    return (
      <div className="flex flex-col">
        {filter(arquivos, (arquivo) => ehImagem(arquivo.filetype)).map(
          (arquivo: any) => (
            <DownloadDaApi
              nomeDoArquivo={arquivo.title}
              key={arquivo.id}
              lazy={false}
              url={`/download?${queryString.stringify({
                url: get(arquivo, "url_private"),
              })}`}
            >
              {(base64) => (
                <img
                  alt={`anexo da mensagem: ${arquivo.title}`}
                  className="md:h-48 h-36 mb-2 cursor-pointer"
                  src={base64}
                />
              )}
            </DownloadDaApi>
          )
        )}
      </div>
    );
  }
);
