import { observer } from "mobx-react-lite";
import classnames from "classnames";
import React from "react"
import { useMobxStores } from "../../hooks/useMobxStores";
import { FormularioDeMensagem } from "./FormularioDeMensagem";
import { X } from "react-feather";
import { ItemDaListaThread } from "../mensagens/ItemDaListaThread";

const BarraLateral = ({ canalSelecionado }: { canalSelecionado: any }) => {
  const { messages } = useMobxStores();
  const {
    mensagensDaThread,
    threadAberta,
    obterMensagensThread,
    definirThreadAberta,
  } = messages;
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handler = () => {
      if (window.innerHeight <= 600) {
        setIsMobile(true);
      }
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  const obterMensagens = React.useCallback(
    () => obterMensagensThread(threadAberta?.ts!), [threadAberta]
  )

  return (
    <div
      className={
        classnames(`
          z-10 fixed right-0 top-0 shadow-2xl bg-gray-50
          min-h-full h-full md:w-2/4 w-screen md:max-w-sm
          transition duration-500 ease-in-out transform
        `,
        {
          'hidden': !threadAberta,
        },
      )}
    >
      <div className='flex justify-between p-4 border-b border-gray-400'>
        <div className='text-lg font-semibold'>Conversa</div>
        <X className='cursor-pointer' onClick={() => definirThreadAberta(null)} />
      </div>
      <div
        className='p-4 overflow-auto'
        style={{ height: isMobile ? 'calc(100% - 100px)' : 'calc(100% - 200px)' }}
      >
        {threadAberta && 
          <div className='border-b border-gray-400'>
            <ItemDaListaThread
              index={0}
              data={[threadAberta]}
            />
              {threadAberta?.reply_count &&
                <div className='text-sm font-medium text-gray-400'>
                  {threadAberta?.reply_count} respostas
                </div>
              }
          </div>}
        {mensagensDaThread.map((_, indice) =>
          <ItemDaListaThread
            key={indice}
            index={indice}
            data={mensagensDaThread}
          />
        )}
      </div>
      <FormularioDeMensagem
        formClass='border-gray-100 m-4'
        aposEnviarMensagem={obterMensagens}
        canalSelecionado={canalSelecionado}
      />
    </div>
  )
}

export default observer(BarraLateral);