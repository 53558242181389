const regexSites = /(https?:\/\/)?(\S+\.)?(whereby)\.com\/\S+|(https?:\/\/)?(zoom)\.us\/.\/\d+(\?pwd=[a-zA-Z0-9]+)?|(https?:\/\/)?(meet)\.google\.com\/\w{3}-\w{4}-\w{3}/

export const validaLinkAudioVideo = (link: string): boolean => {
  return regexSites.test(link.replace(/<|>/g, ''))
};

export const extraiLinkAudioVideo = (link: string): string => {
  return link.replace(/<|>/g, '').match(regexSites)![0];
};

export const extraiSiteDoLink = (link: string): string => (
  link.match(/whereby|google|zoom/)![0]
);