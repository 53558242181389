import axios, { AxiosRequestConfig } from "axios";
import merge from "lodash/merge";
import { authenticator } from "../stores/authenticator";

const expirarSessaoSeNaoAutorizado = <T extends { status: number }>(resposta: T) => {
  if (resposta.status === 401) authenticator.expireSession()
  return resposta
}

export class API {
  static obterURLDoAvatar(hash: string) {
    return `${process.env.REACT_APP_URL_DA_API}/avatar?${new URLSearchParams([["hash", hash,]])}`
  }
  static fetch = (url: string, options: RequestInit = {}) => {
    return fetch(
      `${process.env.REACT_APP_URL_DA_API}${url}`,
      merge(
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("xapps:chat@token")}`,
          },
        },
        options
      )
    ).then(expirarSessaoSeNaoAutorizado)
  };

  static fetchComProgresso = (
    url: string,
    options: AxiosRequestConfig = {}
  ) => {
    return axios.request({
      url: `${process.env.REACT_APP_URL_DA_API}${url}`,
      ...merge(
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("xapps:chat@token")}`,
          },
        },
        options
      ),
    }).then(expirarSessaoSeNaoAutorizado);
  };
}
