import React from 'react';
import { IconProps } from 'react-feather';

interface DropdownItemProps {
  Icon: React.FC<IconProps>;
  label: string;
  onClick(): void;
}

const DropdownItem: React.FC<DropdownItemProps> = ({
  Icon,
  label,
  onClick,
}) => {
  return (
    <li
      onClick={onClick}
      className="inline-flex py-2 px-6 cursor-pointer hover hover:bg-blue-800 hover:text-white"
    >
      <Icon className="mr-4" /> {label}
    </li>
  );
};

export { DropdownItem };
